//Global variables
class Dom {
    constructor() {
        this.body = document.body;

        this.openedSubnaviClassName = 'navItemLayoutOpened';
        this.openedNaviClassName = 'open';
        
        this.mainNaviOverlayClassName = 'mainnav_overlay';
        this.mainNaviOverlayVisibleClassName = 'mainnav_overlay-visible';

        this.navigation = document.querySelector('#navigationToScrape');
        this.desktopHeader = document.querySelector('.header-items');
        this.mobileLogo = document.querySelector('#mobile-logo');
        this.navigationWrapper = document.querySelector('.navWrapper');

        this.navigationUL = this.navigation.querySelector('ul.nav');
        this.navigationULMobile = this.navigation.querySelector('ul.nav-mobile');
        this.dropdownLayouts = this.navigationWrapper.querySelectorAll('.navItemLayout');

        this.openedSubnavi = this.navigationWrapper.querySelector("." + this.openedSubnaviClassName);
        this.openedNavi = this.navigationWrapper.querySelector("." + this.openedNaviClassName);
        
        this.langMenu = document.querySelector('.sf-lang-selector');
        
        if(this.langMenu) {
            this.languages = this.langMenu.querySelectorAll('.li');
        }

        this.quickLinks = this.desktopHeader.querySelector('.quicklinks');

        this.tablet = 992;
        this.singleNavigationContainer = document.querySelector('.sf-simple-nav-container');
    }
  }

  export default new Dom();
