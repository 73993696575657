
import Dom from './dom'
import {closeSubNav} from './Helpers'

export function langMenu() {
    var langMenu = Dom.langMenu;
    if (langMenu == undefined || langMenu.getElementsByTagName('a') == undefined || langMenu.getElementsByTagName('a').length == 0 ) {
        return;
    }

    langMenu.getElementsByTagName('a')[0].addEventListener("click", function (event) {
        var self = event.currentTarget;
        if (window.innerWidth < Dom.tablet) {
            var icon = Dom.navigationWrapper.querySelector(".lang-icon");
            var mobLang = Dom.navigationWrapper.querySelector(".mobile-lang");
            mobLang.removeAttribute("style");

            if (document.getElementById("hamburgerCheckboxHiden").checked === false && mobLang.classList.contains("collapsed") === true) {
                document.getElementById("hamburgerCheckboxHiden").checked = true;
                icon.classList.add("active");
                mobLang.classList.toggle("collapsed");
            }
            else if (document.getElementById("hamburgerCheckboxHiden").checked === true && mobLang.classList.contains("collapsed") === false) {
                document.getElementById("hamburgerCheckboxHiden").checked = false;
                mobLang.classList.toggle("collapsed");
                icon.classList.remove("active");
            }
        }
        else {
            closeSubNav();

            var subMenu = Dom.langMenu.querySelector('li ul');
            if (self.classList.contains('open')) {
                subMenu.classList.remove('visible');
                self.classList.remove('open');
            } else {
                subMenu.classList.add('visible');
                self.classList.add('open');
            }
        }

        event.preventDefault();
    });
}
