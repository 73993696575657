import Dom from './dom'
import {closeSubNav} from './Helpers'

export function mobileInit() {
    if (window.innerWidth < Dom.tablet) {

        Dom.desktopHeader.style.display = "none";
        Dom.mobileLogo.style.display = "";

        clearMobileNav();

        if (document.querySelector('.mobile-lang')) {
            return;
        }

        if (!Dom.langMenu) {
            return;
        }

        if(Dom.langMenu) {
            var langClone = Dom.langMenu.cloneNode(true);
            Dom.navigationWrapper.appendChild(langClone).classList.add("mobile-lang");
        }

        var selector = Dom.navigationWrapper.querySelector(".mobile-lang");
        selector.classList.add("collapsed");
    }
    else {
        Dom.desktopHeader.style.display = "";
        Dom.mobileLogo.style.display = "none";
    }
}

export function initHamburgerButton(mobileNav) {
    var hamburger = document.getElementById("hamburger");
    hamburger.removeAttribute("style");
    hamburger.onclick = function () {
        var selector = Dom.navigationWrapper.querySelector(".mobile-lang");

        if (selector.classList.contains("collapsed") === false) {
            document.getElementById("hamburgerCheckboxHiden").checked = true;
            return;
        }

        mobileNav.removeAttribute("style");
        mobileNav.classList.toggle("collapsed");
    };
}

export function clearMobileNav() {
    //close hamburger btn
    closeSubNav();
    //make sure the main nav is hidden
    var navigationUL = Dom.navigation.querySelector('ul.nav');
    navigationUL.style.display = "none";

    document.getElementById("hamburgerCheckboxHiden").checked = false;

    var mobileNav = Dom.navigation.querySelector('ul.nav-mobile');
    mobileNav.removeAttribute("style")
    initHamburgerButton(mobileNav);
    //collapse the navigation
    if (mobileNav.classList.contains("collapsed") === false) {
        mobileNav.classList.add("collapsed");
    }

    Array.prototype.slice.call(document.getElementsByClassName("mobile-dropdown"))
        .forEach(function (e) {
            if (e.classList.contains('collapse') == false) {
                e.classList.toggle("collapse");
            }
        });
}

export function clearMobileLang() {
    var mobLang = Dom.navigationWrapper.querySelector(".mobile-lang");
    if (mobLang.classList.contains('collapsed') == false) {
        mobLang.classList.toggle("collapsed");
    }
    mobLang.style.display = "none";

}

