import Dom from './dom'

/**
 * This function closes opened subnavi of navigation
 */
export function closeSubNav() {
    let openedSubnavi = Dom.navigationWrapper.querySelector("." + Dom.openedSubnaviClassName);
    let openedNavi = Dom.navigationWrapper.querySelector("." + Dom.openedNaviClassName);

    if (openedNavi) {
        openedNavi.classList.remove(Dom.openedNaviClassName);
    }
    if (openedSubnavi) {
        openedSubnavi.classList.remove(Dom.openedSubnaviClassName);
    }
}

export function isEmpty(element) {
    if (element == undefined) {
        return true;
    }
    return element.innerHTML;
}

export function existDrodpown(dropdown) {
    if (isEmpty(dropdown) !== '') {
        return true;
    }

    return false;
}

export function closeLangMenu() {
    var langDropdown = Dom.navigationWrapper.querySelector('.lang-menu ul.visible');
    if (langDropdown) {
        langDropdown.classList.remove('visible');
        Dom.navigationWrapper.querySelector('.lang-menu a.open').classList.remove('open');
    }
}
