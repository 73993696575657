import {mobileInit} from './Mobile'
import {Desktop} from './Desktop'
import {langMenu} from './LanguageMenu'
import Dom from './dom'

export const Megamenu = {
    init() {
        Desktop();
        langMenu();
        mobileInit();
        Dom.navigation.style.visibility = 'visible';
    }
 }
