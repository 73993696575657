import Dom from './dom'
import { closeSubNav, isEmpty, existDrodpown, closeLangMenu } from './Helpers'
import { clearMobileLang, mobileInit } from './Mobile'


/* navParent sections: [Helper]
* - Navigation widget can be configured incorrectly by including more then one link so use always first item
* - Use navigation Mek Megamenu + Sublinks template
* - Select one parent link with no children elements
* */
export function Desktop() {

    var _this = this;

    MainnaviOverlayClass();

    var thisNavigationWrapper = Dom.navigationWrapper;
    var navigationUL = Dom.navigationUL;
    var navigationULMobile = Dom.navigation.querySelector('ul.nav-mobile');
    var navParentLinkContainer = thisNavigationWrapper.querySelectorAll('.navParent');
    var navParentLinks = thisNavigationWrapper.querySelectorAll('.navParent ul li:first-child'); // [Helper]

    if (!navigationUL) {
        return;
    }

    // add empty placeholder li-node
    navigationULMobile.appendChild(document.createElement('li')).setAttribute("class", "header languages-quicklinks-wrapper");

    // generate desktop and mobile main nav parent links
    Array.prototype.slice.call(navParentLinks).forEach(function (e) {
        e.classList.add('main-nav')
        navigationUL.appendChild(e);
        navigationULMobile.appendChild(e.cloneNode(true));
    });

    // hide default parent links on init
    Array.prototype.slice.call(navParentLinkContainer).forEach(function (e) {
        if (isEmpty(e.querySelectorAll('li'))) {
            e.hide();
        }

        e.setAttribute('data-offset', (e.offsetTop) - 100);
        e.style.display = 'none';
    });

    // generated mobile navigation markup
    if (navigationULMobile) {
        var navigationULMobileLinks = navigationULMobile.querySelectorAll('li.main-nav');
        Array.prototype.slice
        .call(navigationULMobile.querySelectorAll("li.main-nav"))
        .forEach(function(e) {
          var self = e
          var dropdown = thisNavigationWrapper.querySelectorAll(".navItemLayout")[
            Array.prototype.indexOf.call(navigationULMobileLinks, e)
          ]
          if (existDrodpown(dropdown)) {
            // Go through all ul-s
            Array.prototype.slice
              .call(dropdown.querySelectorAll("ul"))
              .forEach(function(e) {
                if (e.classList.contains("nav")) {
                  self.classList.add("mobile-dropdown")
                  self.childNodes[0].classList.add("dropdown-link")
                  self.appendChild(e.cloneNode(true))
                }
              })
          }
        })

        // Quicklinks
        if (navigationULMobile.querySelectorAll('.quicklinks').length == 0) {
            var quickLinksClone = Dom.quickLinks.cloneNode(true);
            Dom.navigationULMobile.querySelector(".header").appendChild(quickLinksClone);
        }

        // Language selector
        if (Dom.langMenu) {
            if (navigationULMobile.querySelectorAll('.sf-lang-selector').length == 0) {
                var langClone = Dom.langMenu.cloneNode(true);
                Dom.navigationULMobile.querySelector(".header").appendChild(langClone);
            }
        }

        // Close button
        if (navigationULMobile.querySelectorAll('#close-hamburger').length == 0) {
            var closeBtn = document.createElement('div');
            closeBtn.id = 'close-hamburger';
            Dom.navigationULMobile.querySelector('.header').appendChild(closeBtn);
        }

        //Event delegation for dynamic close button
        document.addEventListener('click', function (e) {
            if (e.target && e.target.id == 'close-hamburger') {
                Dom.navigation.querySelector('ul.nav-mobile').removeAttribute("style");
                Dom.navigation.querySelector('ul.nav-mobile').classList.add("collapsed");
            }
        });

        // Hide mobile navigation by default
        navigationULMobile.style.display = 'none';
    }

    // prevent default behavior of the link
    var navigationUlLinks = navigationUL.querySelectorAll('li a');
    Array.prototype.slice.call(navigationUlLinks).forEach(function (item) {
        var dropdownIndex = Array.prototype.indexOf.call(navigationUlLinks, item);
        var dropdown = thisNavigationWrapper.querySelectorAll('.navItemLayout .navDropdown')[dropdownIndex];
        if (isEmpty(dropdown) !== '') {
            item.onclick = function (e) {
                e.preventDefault();
            }
        } else {
            item.parentNode.classList.add('link');
        }
    });

    
  // Detect navigation clicks on mobile
    let navigationChildren = navigationULMobile.querySelectorAll(
        ".mobile-dropdown > a"
    )

    let reset = function(elements, exceptionElement) {
        for (let i = 0; i < elements.length; i++) {
            if (
                elements[i] !== exceptionElement &&
                !elements[i].classList.contains("collapse")
            ) {
                elements[i].classList.add("collapse")
            }
        }
    }

    for (let i = 0; i < navigationChildren.length; i++) {
        navigationChildren[i].addEventListener("click", function(e) {
            let current = this
            e.preventDefault()

            reset(navigationChildren, current)

            current.parentNode.classList.toggle("collapse")
        })
    }

    // Detect all clicks on the document
    document.addEventListener("click", function (event) {
        // If user clicks inside the element, do nothing
        if ($(event.target).closest(".navItemLayout") || $(event.target).closest(".main-nav")) {
            return;
        }
        // If user clicks outside the element, hide it!
        if (document.querySelector('.navItemLayoutOpened') != null) {
            document.querySelector('.navItemLayoutOpened').classList.remove('navItemLayoutOpened');
            if (document.querySelector('.main-nav .open') != null) {
                document.querySelector('.main-nav .open').classList.remove('open');
            }
            if (document.querySelector('.main-nav.active') != null) {
                document.querySelector('.main-nav.active').classList.remove('active');
            }            
        }
    });

    // desktop parent click function if there is a dropdown container
    navigationUL.addEventListener('click', function (e) {
        var matches = e.target.matches ? e.target.matches('li a') : e.target.msMatchesSelector('li a');
        if (e.target && matches) {
            var self = e.target || e.srcElement;
            var dropdownLayoutCurrent = Dom.dropdownLayouts[Array.prototype.indexOf.call(navigationUlLinks, self)];

            if (e.target.parentNode.classList.contains("link") === false) {
                closeLangMenu();

                if (self.classList.contains('open')) {
                    self.classList.remove('open');
                    dropdownLayoutCurrent.classList.remove('navItemLayoutOpened');
                    Dom.body.classList.remove('mainnav_overlay-visible');
                } else {
                    var openDropdown = navigationUL.querySelector('.open');
                    if (openDropdown) {
                        openDropdown.classList.remove('open');
                        var openedDropdown = Dom.dropdownLayouts[Array.prototype.indexOf.call(navigationUlLinks, openDropdown)];
                        openedDropdown.classList.remove('navItemLayoutOpened');
                        Dom.body.classList.remove('mainnav_overlay-visible');
                    }

                    self.classList.add('open');
                    dropdownLayoutCurrent.classList.add('navItemLayoutOpened');
                    dropdownLayoutCurrent.style.height = 'auto';
                    Dom.body.classList.add('mainnav_overlay-visible');
                }
            }
        }
    });

    //window.innerWidth < Dom.tablet
    // On resize close dropdown
    window.matchMedia("(min-width: 992px)").addListener(function (e) {
        if (e.matches) {
            navigationUL.removeAttribute("style");
            navigationULMobile.style.display = 'none';
            var ham = document.getElementById("hamburger");
            ham.style.display = "none";
            clearMobileLang();
            Dom.desktopHeader.style.display = "";
            Dom.mobileLogo.style.display = "none";
        } else {
            Dom.desktopHeader.style.display = "none";
            Dom.mobileLogo.style.display = "";
            closeSubNav();
            closeLangMenu();
            mobileInit();
        }
    });
}

/** Functions add overlay class if needed */
function MainnaviOverlayClass() {
    if (!document.querySelectorAll(Dom.mainNaviOverlayClassName).length) {
        var mainNavOverlay = document.createElement('div');
        mainNavOverlay.classList.add(Dom.mainNaviOverlayClassName)
        Dom.body.appendChild(mainNavOverlay);
    }
}
